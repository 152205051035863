import React from "react"

export default function Heading({ textAlign, children }) {
  console.log(textAlign)
  if (textAlign === "left") {
    return (
      <h1 className="font-serif text-left pb-5 text-5xl font-black tracking-tight text-gray-900 leading-10">
        {children}
      </h1>
    )
  } else {
    return (
      <h1 className="font-serif text-center pb-5 text-5xl font-black tracking-tight text-gray-900 leading-10">
        {children}
      </h1>
    )
  }
}
